@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.member-info {
  $breakpoint: 600px;
  &__section-wrapper {
    margin-bottom: spacing.$xxl;
  }

  &__top {
    margin-bottom: spacing.$l;
    position: relative;
    background-color: light.$surface-primary-default;
  }

  &__banner {
    margin: spacing.$m 0;
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: spacing.$xs;
    max-width: $breakpoint;
    margin: spacing.$l 0;

    &--wrap {
      @media (max-width: $breakpoint) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
      }
    }
  }

  &__book {
    > * {
      height: 100%;
    }
  }

  &__messages {
    display: flex;
    margin-top: spacing.$l;
  }

  &__link {
    display: flex;
    padding: spacing.$m 0;
  }
}
